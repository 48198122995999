<template>
  <v-card flat class="mt-2">
    <form-data :references.sync="formReferences" :model="hourObj" ref="absenceHourReference">
      <template slot="section-top">
        <!-- <Slider :timeline="timeline"></Slider> -->
        <!-- <v-range-slider :tick-labels="sliderOptions.sliderLabels" :value="sliderOptions.value" :min="sliderOptions.min" :max="sliderOptions.max" readonly dense tick-size="3"></v-range-slider> -->
      </template>
    </form-data>
  </v-card>
</template>
<script>
import FormTemplate from '../../../../src/components/FormTemplate'
import { mapGetters } from 'vuex'
import CONSTANTS from '@/assets/json/constants.json'
import moment from 'moment'
export default {
  props: ['hourObj', 'listItems', 'isWeekLocked', 'backLink'], /* 'sliderOptions', */
  data () {
    return {
      HOURS: 'hours',
      hour_enter_style: 1, // 1 => hours ; 2 => from,till
      isValidTime: false
    }
  },
  components: {
    'form-data': FormTemplate
  },
  computed: {
    ...mapGetters(['formType']),
    formReferences () {
      const formRef = {
        /* title: this.hourObj.id ? this.$t('message.timer.editHours') : this.$t('message.timer.addHours'), */
        properties: [{
          model: 'start_date',
          max: 'enddatePicker',
          type: this.formType.DATEPICKER,
          rules: this.$_requiredValidation,
          picker: 'datePicker',
          menu: 'dateMenu',
          color: 'primary lighten-1',
          label: this.$t('message.projects.startDate'),
          disabled: this.hourObj.id ? this.isWeekLocked || this.isReadOnly : false,
          class: 'xs6 sm4 md3 lg3'
        }, {
          model: 'end_date',
          min: 'datePicker',
          type: this.formType.DATEPICKER,
          rules: this.$_requiredValidation,
          picker: 'enddatePicker',
          menu: 'enddateMenu',
          color: 'primary lighten-1',
          label: this.$t('message.projects.endDate'),
          disabled: this.hourObj.id ? this.isWeekLocked || this.isReadOnly : false,
          class: 'xs6 sm4 md3 lg3'
        }, {
          model: 'amount',
          type: this.formType.TEXT,
          rules: this.$_HourValidation,
          label: this.$t('message.timer.hours'),
          class: 'xs12 sm4 md3 lg3',
          disabled: this.isWeekLocked || this.isReadOnly
        }, {
          model: 'starttime',
          max: 'endtime',
          type: this.formType.TIMEPICKER,
          rules: this.$_requiredValidation,
          menu: 'startTimeMenu',
          label: this.$t('message.timer.startTime'),
          disabled: this.isWeekLocked || this.isReadOnly,
          class: 'xs6 sm4 md3 lg3'
        }, {
          model: 'endtime',
          min: 'starttime',
          type: this.formType.TIMEPICKER,
          rules: this.$_requiredValidation,
          menu: 'endTimeMenu',
          label: this.$t('message.timer.endTime'),
          disabled: this.isWeekLocked || this.isReadOnly,
          class: 'xs6 sm4 md3 lg3'
        }, {
          model: 'work_id',
          type: this.formType.SELECT,
          rules: this.$_requiredValidation,
          items: this.listItems.workTypes.filter(x => !x.is_billable),
          select_text: (val) => `${val[`${this.$i18n.locale}_name`]}`,
          select_value: 'id',
          disabled: this.isWeekLocked || this.isReadOnly,
          label: this.$t('message.common.nonbillableworktype'),
          class: 'xs12 sm4 md3 lg3'
        }, {
          model: 'comments',
          type: this.formType.TEXTAREA,
          rules: this.$_requiredValidation,
          disabled: this.isWeekLocked || this.isReadOnly,
          label: this.$t('message.common.comments'),
          class: 'xs12 sm4 md6 lg6',
          rows: 3
        }, {
          model: 'not_approved_comment',
          type: this.formType.TEXTAREA,
          rules: [],
          // eslint-disable-next-line
          is_show: this.hourObj.state === CONSTANTS.NOT_APPROVED,
          readonly: true,
          label: this.$t('message.timer.notApproved'),
          class: 'xs12 sm4 md6 lg6 custom_opacity_reduced',
          rows: 3
        }],
        buttons: [{
          name: 'action_handler',
          color: 'success',
          label: this.hourObj.id ? this.$t('message.common.update') : this.$t('message.common.save'),
          click: this.saveUpdateHandler,
          disabled: this.isWeekLocked || this.isReadOnly,
          loading: this.loading,
          is_show: true
        }, {
          name: 'back',
          color: 'info',
          click: () => this.$router.go(-1), // this.$router.push(this.backLink),
          label: this.$t('message.login.back'),
          to: this.backLink,
          is_show: true
        }, {
          name: 'delete',
          color: 'error',
          click: this.deleteHandler,
          label: this.$t('message.common.delete'),
          is_show: this.hourObj.id ? !this.isReadOnly : false
        }]
      }
      if (this.hour_enter_style === 1) formRef.properties.splice(3, 2)
      if (this.hour_enter_style === 2) formRef.properties.splice(2, 1)
      return formRef
    },
    isReadOnly () {
      return this.hourObj.state === CONSTANTS.DELIVERED || this.hourObj.state === CONSTANTS.APPROVED
    }
  },
  mounted () {
    this.hourObj.start_date = this.hourObj.date
    this.hourObj.datePicker = this.$formatter.formatDate(this.hourObj.date, 'DD.MM.YYYY', 'YYYY-MM-DD')
    this.setHourEnterStyle()
    // this.sliderLablesHide()
  },
  methods: {
    saveUpdateHandler () {
      if (this.$refs.absenceHourReference.$refs.validateForm.validate()) {
        this.loading = true
        let model = this.$formatter.cloneVariable(this.hourObj)
        model.state = model.id ? model.state !== CONSTANTS.NOT_APPROVED ? model.state : CONSTANTS.NOT_DELIVERED : CONSTANTS.NOT_DELIVERED
        if (this.hour_enter_style === 2) {
          model.start_date = `${model.start_date} ${model.starttime}`
          model.end_date = `${model.end_date} ${model.endtime}`
          const start = moment(model.start_date, 'DD.MM.YYYY HH:mm')
          const end = moment(model.end_date, 'DD.MM.YYYY HH:mm')
          model.amount = end.diff(start, 'hours', true)
          model.amount = parseFloat(model.amount).toFixed(2)
        } else {
          model.start_date = moment(model.start_date, 'DD.MM.YYYY').format('DD.MM.YYYY 00:00:00')
          model.end_date = moment(model.end_date, 'DD.MM.YYYY').format('DD.MM.YYYY 23:59:59')
        }
        const transformData = {
          date: { list: ['start_date', 'end_date'], from: 'DD.MM.YYYY HH:mm', to: 'YYYY-MM-DDTHH:mm:ss' },
          number: { list: ['amount'], format: 'replaceCommaWithDot' }
        }
        model = this.$formatter.formatModel(model, transformData)
        model.user_id = this.$store.state.auth.userDetails ? this.$store.state.auth.userDetails.id : 0
        model.type = CONSTANTS.NON_WORKING
        model.break_hr = null
        // const url = this.HOURS
        // this.$api.saveUpdateHandler(url, model)
        //   .then((response) => {
        //     response.snackbar.text = this.$t(response.snackbar.text)
        //     this.$root.$emit('snackbar', response.snackbar)
        //     this.$router.go(-1)
        //     // this.$router.push('/calendar')
        //   })
        //   .finally(() => {
        //     this.loading = false
        //   })
        const totalAbsenceValue = this.hourObj.total.toString().split('(')[1].split(')')[0].replace(',', '.')
        const totalNormalValue = this.hourObj.total.toString().split('(')[0].replace(',', '.')
        if (!this.hourObj.id) Number(totalNormalValue) + Number(model.amount) + Number(totalAbsenceValue) > 24 ? this.isValidTime = false : this.isValidTime = true
        else (Number(totalNormalValue)) + Number(model.amount) > 24 ? this.isValidTime = false : this.isValidTime = true
        if (this.isValidTime) {
          const url = this.HOURS
          this.$api.saveUpdateHandler(url, model)
            .then((response) => {
              response.snackbar.text = this.$t(response.snackbar.text)
              this.$root.$emit('snackbar', response.snackbar)
              this.$router.push('/calendar')
            })
            .finally(() => {
              this.loading = false
            })
        } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.hourExceedNotification' })
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.validationIssue' })
    },
    setHourEnterStyle () {
      if (this.hourObj.start_date && this.hourObj.end_date) {
        const isSame = moment(this.hourObj.start_date, 'DD.MM.YYYY').isSame(moment(this.hourObj.end_date, 'DD.MM.YYYY'), 'day')
        this.hour_enter_style = isSame ? 2 : 1
      } else this.hour_enter_style = 1
    },
    setDefaultValues (data) {
      const { list, prop } = data
      const item = list.find(x => x.isdefault)
      if (item) {
        const model = Object.assign({ ...this.hourObj }, { [prop]: item.id })
        this.hourObj = model
      }
    },
    deleteHandler () {
      const payload = { url: `hours/${this.hourObj.id}`, module: 'hours', isSingle: true }
      this.$store.commit('showDeleteDialog', payload)
    },
    sliderLablesHide () {
      setTimeout(() => {
        const sliderTickLabels = document.getElementsByClassName('v-slider__ticks-container--always-show')
        if (sliderTickLabels.length > 1) {
          const element = sliderTickLabels[1]
          const visibleLabels = []
          for (var i = 0; i < element.childNodes.length; i += 3) {
            visibleLabels.push(i)
          }
          const lastIndex = element.childNodes.length - 1
          if (!visibleLabels.includes(lastIndex)) visibleLabels.push(lastIndex)
          element.childNodes.forEach((ele, index) => {
            const lblElement = ele.childNodes[0]
            if (lblElement) {
              if (!visibleLabels.includes(index)) {
                lblElement.style.display = 'none'
              } else {
                lblElement.style.display = 'block'
              }
            }
          })
        }
      }, 100)
    }
  },
  watch: {
    'hourObj.datePicker' (val) {
      if (val) {
        this.hourObj.start_date = this.$formatter.formatDate(val, 'YYYY-MM-DD', 'DD.MM.YYYY')
      }
    },
    'hourObj.start_date' (val) {
      if (val) {
        this.$eventBus.$emit('getactiveDateHourRecords', val)
        this.setHourEnterStyle()
      }
    },
    'hourObj.enddatePicker' (val) {
      if (val) {
        this.hourObj.end_date = this.$formatter.formatDate(val, 'YYYY-MM-DD', 'DD.MM.YYYY')
      }
    },
    'hourObj.end_date' (val) {
      if (val) {
        this.$store.dispatch('checkMonthLockedStatus', val).then((response) => { // true or false value
          if (response) this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.calendar.weekLock' })// notification show for month locked
        })
        this.setHourEnterStyle()
      }
    },
    'listItems.workTypes' (val) {
      if (val && !this.hourObj.id) {
        const obj = val.find(x => x.is_default && !x.is_billable)
        if (obj) this.hourObj.work_id = obj.id
      }
    }
    /* 'sliderOptions.sliderLabels' (val) {
      // v-slider__ticks-container v-slider__ticks-container--always-show, v-slider__tick
      this.sliderLablesHide()
    } */
  }
}
</script>
